export const mails: TableModule = {
    name: "mails",
    route: "spa.settings.mails",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "id",
            sortable: true,
            mobile: true,
            width: "w-[75px]"
        },
        {
            name: "recipient",
            sortable: true,
            pageLink: true,
            mobile: true,
        },
        {
            name: "mail_template_name",
            sortable: true,
            mobile: true
        },
        {
            name: "status",
            sortable: true,
            mobile: true
        },
        {
            name: "updated_at",
            sortable: true,
        }
    ]
}