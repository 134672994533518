import debounce from "lodash/debounce"

export const useTableStore = () => {

    const name = ref(null)
    const route = ref(null)
    const defaultOrder = ref(null)
    const data = ref({}) // store previously fetched data
    const columns = ref(null)
    const filters = ref(null)
    const quickFilters = ref(null)
    const tools = ref(null)

    const activeTool = ref(null)
    const loading = ref(false)
    const inverseSelection = ref(false)
    const selectedRows = ref([])
    const filterOptionCache = ref([])

    const listing = ref({
        search: "",
        orderBy: "id",
        orderDirection: "asc",
        perPage: 10,
        perPageOptions: [10, 50, 100],
        columns: 6,
        page: 1,
        lastPage: 1,
        total: 0,
        custom: {},
        filter: [],
        links: []
    })

    const rows = computed(() => {
        return data.value[listing.value.page] || []
    })

    const selectedCount = computed(() => {
        return inverseSelection.value ? listing.value.total - selectedRows.value.length : selectedRows.value.length
    })

    function resetSelection() {
        inverseSelection.value = false
        selectedRows.value = []
    }

    async function preloadPages() {
        const {$apiRoute} = useNuxtApp()
        for (let i = -1; i <= 2; i++) {
            const pageToLoad = listing.value.page + i
            if (pageToLoad < 1 || pageToLoad > listing.value.lastPage || data.value[pageToLoad]) continue
            try {
                const response = await $lara.get($apiRoute(route.value + '.index'), {
                    params: {...listing.value, page: pageToLoad}
                })
                data.value[pageToLoad] = response.data
            } catch (error) {
                console.log(error)
            }
        }
    }

    const preloadPagesDebounced = debounce(preloadPages, 1000, {maxWait: 5000})

    async function fetchData(fresh = false) {
        const {$apiRoute} = useNuxtApp()

        loading.value = true

        preloadPagesDebounced?.cancel()

        if (fresh) {
            listing.value.page = 1
            data.value = {}
        } else if (data.value[listing.value.page]) {
            data.value = {}
        }

        $lara.get($apiRoute(route.value + '.index'), {
            params: {...listing.value}
        }).then((response: Object) => {
            data.value[listing.value.page] = response.data
            listing.value.page = response.meta?.current_page ?? response.current_page
            listing.value.lastPage = response.meta?.last_page ?? response.last_page
            listing.value.total = response.meta?.total ?? response.total
            listing.value.links = response.meta?.links ?? response.links

            preloadPagesDebounced()
        }).finally(() => {
            loading.value = false
        })
    }

    async function postToolData(tool: string, data: Object = {}) {
        const {$apiRoute} = useNuxtApp()
        return $lara.post($apiRoute(route.value + '.action'), {
            rawResponse: data.rawResponse ?? false,
            body: {
                ...listing.value,
                ...listing.value.custom,
                selection: {
                    inverseSelection: inverseSelection.value ?? false,
                    selectedIds: selectedRows.value ?? []
                },
                tool,
                toolData: data
            }
        })
    }

    function initialize(module: string) {
        const moduleDefinition = tableModules[module]
        name.value = moduleDefinition.name
        route.value = moduleDefinition.route
        defaultOrder.value = moduleDefinition.defaultOrder ?? null
        columns.value = moduleDefinition.columns
        filters.value = moduleDefinition.filters ?? null
        quickFilters.value = moduleDefinition.quickFilters ?? null
        tools.value = moduleDefinition.tools ?? null

        if (defaultOrder.value) {
            listing.value.orderBy = defaultOrder.value.column
            listing.value.orderDirection = defaultOrder.value.direction
        }

        // Load filters from local storage
        const storedFilters = localStorage.getItem(`filters-${name.value}`)
        if (storedFilters?.length > 0) {
            listing.value.filter = JSON.parse(storedFilters)
        } else if (storedFilters === null) {
            // Apply default quick filters if no filters are stored
            quickFilters?.value?.forEach((filter) => {
                if (filter.default) {
                    listing.value.filter.push(filter)
                }
            })
        }

        // Load orderBy and orderDirection from local storage
        const storedOrderBy = localStorage.getItem(`${name.value}-orderBy`)
        const storedOrderDirection = localStorage.getItem(`${name.value}-orderDirection`)
        if (storedOrderBy) {
            listing.value.orderBy = storedOrderBy
        }
        if (storedOrderDirection) {
            listing.value.orderDirection = storedOrderDirection
        }
    }

    // Watch filters and update local storage
    watch(() => listing.value.filter, (newFilters) => {
        if (newFilters.length > 0) {
            localStorage.setItem(`filters-${name.value}`, JSON.stringify(newFilters))
        } else {
            localStorage.setItem(`filters-${name.value}`, JSON.stringify([]))
        }
    }, {deep: true})

    // Watch orderBy and update local storage
    watch(() => listing.value.orderBy, (newOrderBy) => {
        localStorage.setItem(`${name.value}-orderBy`, newOrderBy)
    })

    // Watch orderDirection and update local storage
    watch(() => listing.value.orderDirection, (newOrderDirection) => {
        localStorage.setItem(`${name.value}-orderDirection`, newOrderDirection)
    })

    return {
        loading,
        columns,
        filters,
        quickFilters,
        tools,
        rows,
        listing,
        inverseSelection,
        selectedRows,
        selectedCount,
        activeTool,
        defaultOrder,
        route,
        filterOptionCache,
        initialize,
        fetchData,
        resetSelection,
        postToolData
    }
}